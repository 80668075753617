<template>
  <div class="login-page">
    <div class="login-page-left" style="background-image: url('https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/3996E0E30878406BB381D06BC92A24DB.jpg')">
      <h1>欢迎登录<br />阿兰贝尔管理系统</h1>
      <div class="login-page-left-bottom">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="login-page-right">
      <div class="login-page-right_left">
        <div class="login-page-right_left_top">
          <div class="lefts"></div>
          <div class="rights"></div>
        </div>
        <div class="login-page-right_left_bottom">
          <div>
            <p class="left_s"></p>
            <p class="right_s"></p>
          </div>
          <div>
            <p class="left_s"></p>
            <p class="right_s"></p>
          </div>
          <div>
            <p class="left_s"></p>
            <p class="right_s"></p>
          </div>
        </div>
      </div>
      <div class="login-page-right_form">
        <!-- <el-form> -->
        <div class="el-form">
          <el-card>
            <h1>Hello,欢迎登录后台</h1>
            <p style="color: #b4b4b4">WELCOME TO THE SYSTEM</p>
            <div>
              <input placeholder="请输入后台账号" class="accountinp" v-model="userid" autofocus/>
            </div>
            <div style="display: flex">
              <input placeholder="请输入短信验证码" class="testinp" v-model="testpwd" />
              <el-button type="text" style="width: 80px; color: #5479ff" @click="testclick" v-show="flag"
                >发送验证码</el-button
              >
              <p v-show="!flag" style="width: 80px; color: #b2b2b2; font-size: 12px; line-height: 16px">
                重新发送({{ codetime }}s)
              </p>
            </div>
            <p>
              <button
                class="land_btn"
                @click="landclick"
                style="color: #fff"
                :disabled="disabled"
                v-loading="landloading"
              >
                登&emsp;&emsp;录
              </button>
            </p>
          </el-card>
        </div>
        <!-- </el-form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      flag: true,
      codetime: 60,
      timeID: 0,
      userid: '',
      testpwd: '',
      regular: '^1[3|4|5|6|7|8|9][0-9]{9}$',
      disabled: false,
      landloading: false,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    keyDown(e) {
      if (e.keyCode === 13) {
        this.landclick()
      }
    },
    // 获取验证码
    testclick() {
      if (this.userid.match(this.regular)) {
        postRequestAnthor('/computer/login/getloginsmscode', { phone: this.userid })
          .then(res => {
            if (res == true) {
              this.flag = false
              this.timeID = setInterval(() => {
                this.codetime--
                if (this.codetime <= 0) {
                  clearInterval(this.timeID)
                  this.flag = true
                  this.codetime = 60
                }
              }, 1000)
              this.$message({
                message: '验证码发送成功，请注意查收',
                type: 'success',
              })
            }
          })
          .catch(() => {
            this.$message.error('验证码发送失败，请重试')
          })
      } else {
        this.$message.error('请输入正确的手机号')
      }
    },
    // 登录
    landclick() {
      this.disabled = true
      if (!this.userid.match(this.regular)) {
        setTimeout(() => {
          this.disabled = false
        }, 2000)
        this.$message.error('请输入正确的手机号')
      } else if (!this.testpwd) {
        setTimeout(() => {
          this.disabled = false
        }, 2000)
        this.$message.error('请输入验证码')
      } else if (this.testpwd && this.userid.match(this.regular)) {
        this.disabled = false
        this.landloading = true
        postRequestAnthor('computer/login/login', {
          phone: this.userid,
          code: this.testpwd,
          loginType: '1',
        })
          .then(res => {
            this.landloading = false
            console.log(res.resources.length);
            if(res.resources.length>0){
              localStorage.setItem('newToken', JSON.stringify(res))
            this.$message({
              message: '登录成功',
              type: 'success',
            })
            if (res.resources[0].webUrl != null) {
              this.$router.push(res.resources[0].webUrl)
            } else {
              this.$router.push(res.resources[1].webUrl)
            }
            }else{
              this.$message.error('暂无任何系统权限，请联系管理员分发权限')
            }
          })
          .catch(() => {
            this.landloading = false
            // this.$message.error('验证码错误')
          })
      } else {
        this.$message.error('请输入正确的手机号及验证码')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.login-page {
  display: flex;
  height: 100%;
  .login-page-left {
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: flex-end;
    flex: 3.9;
    // background-image: url(../../assets/images/login.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    h1 {
      position: relative;
      top: -25%;
      left: 10%;
      font-size: 42px;
      font-weight: normal;
      letter-spacing: 4px;
    }
    .login-page-left-bottom {
      display: flex;
      width: 100%;
      height: 30%;
      padding-left: 28%;
      div {
        margin-right: 50px;
        width: 37px;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .login-page-right {
    display: flex;
    flex: 6.1;
    background-color: #fff;
    .login-page-right_left {
      width: 129px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: -64.5px;
      .login-page-right_left_top {
        width: 129px;
        height: 129px;
        display: flex;
        .lefts {
          border-radius: 64.5px 0 0 64.5px;
          flex: 1;
          background-color: #fff;
        }
        .rights {
          border-radius: 0 64.5px 64.5px 0;
          flex: 1;
          background-color: #7797ea;
        }
      }
      .login-page-right_left_bottom {
        div {
          width: 116px;
          display: flex;
          .left_s {
            width: 58px;
            height: 6px;
            flex: 1;
            background-color: #fff;
          }
          .right_s {
            width: 58px;
            height: 6px;
            flex: 1;
            background-color: #7797ea;
          }
        }
      }
    }
    .login-page-right_form {
      margin-top: -80px;
      flex: 1;
      margin-left: -64.5px;
      justify-content: center;
      align-items: center;
      .el-form {
        margin: 24%;
        .el-card {
          width: 558px;
          height: 547px;
          text-align: center;
          padding: 60px;
          div {
            margin-top: 30px;
          }
        }
        h1 {
          color: #3cadfd;
          // font-weight: normal;
          margin-bottom: 15px;
          font-size: 36px;
          font-weight: 500;
          background-image: -webkit-linear-gradient(top, #5cc4ff, #2853f0);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        input {
          padding-left: 20px;
          width: 100%;
          height: 46px;
          border-radius: 23px;
          border: 0;
          outline: none;
          background-color: #f6f7fb;
        }

        .testinp {
          flex: 1;
        }
      }
      .land_btn {
        height: 46px;
        width: 100%;
        margin-top: 50px;
        background-image: linear-gradient(to right, #5cc8ff, #5477ff);
        border: 0;
        border-radius: 23px;
        cursor: pointer;
        font-size: 16px;
      }
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        font-size: 12px;
        color: #b4b4b4;
      }
    }
  }
}
</style>
